@font-face {
  font-weight: 400;
  font-family: "Rubrik";
  src: url("./assets/fonts/Rubrik.otf") format("opentype");
}

@font-face {
  font-weight: 500;
  font-family: "Rubrik-Medium";
  src: url("./assets/fonts/RubrikMedium.otf") format("opentype");
}

@font-face {
  font-weight: 400;
  font-family: "Rubrik-Light";
  src: url("./assets/fonts/RubrikLight.otf") format("opentype");
}

@font-face {
  font-weight: 800;
  font-family: "Rubrik-Bold";
  src: url("./assets/fonts/RubrikBold.otf") format("opentype");
}
